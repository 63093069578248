// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Dropdown, Tabs } from "tailwindcss-stimulus-components"
Stimulus.register('dropdown', Dropdown)
Stimulus.register('tabs', Tabs)

import { Autocomplete } from 'stimulus-autocomplete'
Stimulus.register('autocomplete', Autocomplete)

import { StreamActions } from "@hotwired/turbo";

StreamActions.scroll_to = function () {
  const target = this.getAttribute("target");
  document.querySelector(target).scrollIntoView({ behavior: "smooth" });
};
